<template>
  <v-container fluid class="bg">
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.bg {
  background: url("../assets/img/lock.jpg") no-repeat fixed center bottom;
  background-size: cover;
  height: 100vh;
}
</style>
